<template>
  <div class="h-full w-full" v-if="isDataReady">
    <BaseTable
      class="h-full"
      :row-height="50"
      :column-defs="columnDefs"
      :row-data="tableData"
      :default-col-def="defaultColDef"
      :no-rows-overlay-component="AgGridNoRowsOverlay"
      :no-rows-overlay-component-params="{
        message: 'There is no unlock data that meets your criteria'
      }"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      :key="isDataReady"
    />
  </div>
  <div v-else-if="tableData?.length || !isDataReady" class="flex flex-col items-center justify-center pr-8">
    <BaseLoader class="m-auto h-5 w-5" />
  </div>
  <div v-else class="flex items-center justify-center">
    <NoDataFound />
  </div>
</template>
<script setup>
import { ref, watch, onMounted, inject, computed } from 'vue';
import { useStore } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { useWatchlistStore } from '@/stores/watchlists';
import { useCoinUnlockApi } from '@/components/coin/unlock/CoinUnlockApi';
import CoinPriceCell from '@/components/coin/unlock/cells/CoinPriceCell.vue';
import AgGridNoRowsOverlay from '@/components/ag_grid/AgGridNoRowsOverlay.vue';
import { DateTime } from 'luxon';
import NoDataFound from '@/components/NoDataFound.vue';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';

const emitter = useEmitter();
const $http = useHttp();
const store = useStore();
const $watchlistStore = useWatchlistStore();
const isDataReady = ref(false);
const selectedRow = ref(null);
const tableData = ref([]);

const props = defineProps({
  unlockCoins: {
    type: Array,
    default: null
  }
});

// SET ALERT DATA
function constructLocalStorageName(s) {
  let path = store.state.path
    .split('.')
    .map((s, i) => {
      return i == 0 ? s : s.charAt(0).toUpperCase() + s.slice(1);
    })
    .join('');
  s = s.charAt(0).toUpperCase() + s.slice(1);
  return `${path}${s}`;
}

const alertData = ref(
  JSON.parse(window.localStorage.getItem(constructLocalStorageName('alertUnlockData'))) || {
    coins: { coins: [] },
    watchlist: { id: null },
    category: { id: null },
    subcategory: { id: null },
    filters: {
      timeframe: '30',
      threshold: '0.00',
      thresholdMetric: 'avg_trading_volume_percent',
      types: [],
      entities: []
    }
  }
);

// FORMATE CONFIGURATIONS
function formatConfigData(data) {
  let config = {};
  for (const [k, v] of Object.entries(data)) {
    if (['watchlist', 'category', 'subcategory'].includes(k)) {
      if (v && (v.uid != null || v.id != null)) {
        config[k] = v;
      }
    } else if ('coins' == k) {
      if (v && v.length > 0) {
        config[k] = v;
      }
    } else {
      config[k] = v;
    }
  }
  return config;
}

onMounted(() => {
  emitter.$on('config:alert', data => {
    alertConfig.value = data.config;
  });
  if (isEmpty(alertConfig.value)) {
    alertConfig.value = formatConfigData(alertData.value);
  }
});
const alertConfig = ref({});

const getWatchlist = async watchlistId => {
  return await $watchlistStore.getWatchlist(watchlistId);
};

const allCategories = ref({});

const setCategoriesWithSubcategories = async () => {
  store.state?.categories?.coin_sub_categories
    ?.filter(cat => cat.id !== 0)
    .forEach(item => {
      const categoryId = item.category_id;
      const id = item.id;
      if (!allCategories.value[categoryId]) {
        allCategories.value[categoryId] = [];
      }
      allCategories.value[categoryId].push(id);
    });
};

// PARAMS & DATA FORMATION
function listToString(list) {
  if (list && typeof list != String && list.length > 0) {
    return list.join(',');
  }
  return 'any';
}

const coins = computed(() => store.state.unlockCoins);
const unlockApi = useCoinUnlockApi();
const alertCoins = ref([]);
const allCoins = computed(() => store.getters.coins);
const getAlertCoins = async () => {
  isDataReady.value = false;
  if (alertConfig.value.watchlist) {
    const selectedWatchlist = await getWatchlist(alertConfig.value.watchlist.id);
    alertCoins.value = selectedWatchlist.entities_uids;
  } else if (alertConfig.value.category) {
    const matchingCoins = [];
    if (Object.keys(allCategories.value).length == 0) {
      await setCategoriesWithSubcategories();
    }
    allCategories.value[alertConfig.value.category.id].forEach(subcategory => {
      matchingCoins.push(allCoins.value.filter(coin => coin.category_ids?.includes(subcategory)));
    });
    const flatCoins = matchingCoins
      .flat()
      .sort((a, b) => b.market_cap - a.market_cap)
      .map(coin => coin.coin_uid);
    alertCoins.value = [...new Set(flatCoins)];
  } else if (alertConfig.value.subcategory) {
    alertCoins.value = allCoins.value
      ?.filter(coin => coin?.category_ids.includes(alertConfig.value.subcategory.id))
      .map(coin => coin.coin_uid);
  } else if (alertConfig.value.coins) {
    alertCoins.value = alertConfig.value.coins;
  } else {
    alertCoins.value = [];
  }
  if (alertCoins.value.length == 1) {
    selectedRow.value = coins.value.find(c => c.coin_uid == alertCoins.value[0]);
  } else {
    selectedRow.value = {};
  }

  let eventFilters = alertConfig.value.filters;
  let coinsIncluded = true;
  if (
    (alertConfig.value?.category || alertConfig.value?.subcategory || alertConfig.value?.watchlist) &&
    !alertConfig.value?.coins
  ) {
    let unlockCoinIds = coins.value.map(coin => coin.coin_uid);
    alertCoins.value = alertCoins.value.filter(item => unlockCoinIds.includes(item));
    if (alertCoins.value?.length == 0) {
      coinsIncluded = false;
      tableData.value = [];
    }
  }
  emitter.$emit('update:coins-availability', { coinsAvailable: coinsIncluded });
  if (eventFilters?.timeframe && eventFilters?.threshold && eventFilters?.thresholdMetric && coinsIncluded) {
    const days = Number(eventFilters.timeframe);
    const todayDate = DateTime.now().toFormat('yyyy-MM-dd');
    const futureDate = DateTime.now().plus({ days }).toFormat('yyyy-MM-dd');
    const eventParams = {
      unlock_types: listToString(eventFilters.types) || 'any',
      unlock_entities: listToString(eventFilters.entities) || 'any',
      coin_uids: alertCoins.value?.join(',') || 'any',
      unlock_date: `gte:${todayDate},lte:${futureDate}`,
      avg_trading_volume_percent: 'gte:0',
      market_cap_percent: 'gte:0',
      supply_percent: 'gte:0',
      watchlist_only: false,
      [eventFilters.thresholdMetric]: `gte:${eventFilters.threshold}`,
      sort: 'unlock_date:asc'
    };
    tableData.value = await unlockApi.getEventsData(eventParams);
    tableData.value = tableData.value?.data || [];
  }
  isDataReady.value = true;
};

watch(
  () => alertConfig.value,
  async (newVal, oldVal) => {
    getAlertCoins();
  },
  { immediate: true, deep: true }
);

const gridApi = ref(null);
const onGridReady = params => {
  gridApi.value = params.api;
};

// TABLE CONFIGURATION
const defaultColDef = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: false
};

const columnDefs = computed(() => {
  let additional_columns = {
    avg_trading_volume_percent: {
      headerName: '% of Avg Trading Vol',
      headerTooltip: 'Unlock Value as percent of Avg Trading Volume',
      field: 'avg_trading_vol_percent',
      valueGetter: params =>
        params.data?.avg_trading_vol_percent ? parseFloat(params.data?.avg_trading_vol_percent) * 100 : null,
      cellRenderer: 'BaseCellPercent',
      type: 'rightAligned'
    },
    market_cap_percent: {
      headerName: '% of Market Cap',
      headerTooltip: 'Unlock Value as percent of Market Cap',
      field: 'market_cap_percent',
      valueGetter: params =>
        params.data?.market_cap_percent ? parseFloat(params.data?.market_cap_percent) * 100 : null,
      cellRenderer: 'BaseCellPercent',
      type: 'rightAligned'
    },
    supply_percent: {
      headerName: '% of Total Supply',
      headerTooltip: 'Unlock Tokens as percent of Total Supply',
      field: 'supply_percent',
      valueGetter: params => (params.data?.supply_percent ? parseFloat(params.data?.supply_percent) * 100 : null),
      cellRenderer: 'BaseCellPercent',
      type: 'rightAligned'
    }
  };
  return [
    [
      {
        headerName: 'Coin',
        headerClass: 'ml-4',
        field: 'coin',
        sortable: false,
        tooltipField: 'coin.name',
        cellRenderer: 'BaseCellCoin'
      }
    ],
    {
      headerName: 'Type',
      field: 'type',
      cellStyle: { display: 'flex', 'align-items': 'center' }
    },
    {
      headerName: 'Entity',
      field: 'entity',
      cellStyle: { display: 'flex', 'align-items': 'center' },
      valueGetter: params => {
        return params?.data?.entity?.length > 14 ? params.data?.entity?.slice(0, 14) + ' ...' : params?.data?.entity;
      },
      tooltipValueGetter: params => params?.data?.entity
    },
    {
      headerName: 'Unlock Date (UTC)',
      headerTooltip: 'Unlock Date',
      field: 'unlock_date',
      cellRenderer: 'BaseCellDateTime',
      type: 'rightAligned',
      cellRendererParams: { dateOnly: true },
      sort: 'asc'
    },
    {
      headerName: 'Unlock Value',
      headerTooltip: 'Unlock Value',
      field: 'unlock_value_usd',
      cellRenderer: CoinPriceCell,
      type: 'rightAligned'
    },
    alertConfig.value?.filters?.thresholdMetric ? additional_columns[alertConfig.value?.filters?.thresholdMetric] : []
  ].flat();
});
</script>
